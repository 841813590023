import React from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Chart from "react-apexcharts";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

const options = ["Action", "Another Action", "Something else here"];

const NeighborhoodChart = ({ modalityChart }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const chartOptions = {
    series: [
      {
        data:
          modalityChart &&
          modalityChart.length &&
          modalityChart.map((m) => m[1]),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      /* colors: colors, */
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories:
          modalityChart &&
          modalityChart.length &&
          modalityChart.map((m) => m[0]),
        labels: {
          style: {
            /* colors: colors, */
            fontSize: "12px",
          },
        },
      },
    },
  };

  return (
    <Card
      variant="outlined"
      sx={{
        pb: 0,
      }}
    >
      <CardContent
        sx={{
          pb: "0 !important",
        }}
      >
        {modalityChart ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mb: 5,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "h3.fontSize",
                    marginBottom: "0",
                  }}
                  gutterBottom
                >
                  Entregas por Bairro
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                  }}
                >
                  Pedidos por bairro
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                }}
              >
                <IconButton
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertOutlinedIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {/* {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={handleClose}
                    >
                      {option}
                    </MenuItem>
                  ))} */}
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "15px",
              }}
            >
              {modalityChart && (
                <Chart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="bar"
                />
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default NeighborhoodChart;
