import React, { useState, useEffect } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  MenuItem,
  Select,
  Chip,
  Table,
  Tooltip,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ExTable from "./ExTable";

const ClientOrdersPerformance = ({ bestClients }) => {
  const [editedClients, setEditedClients] = useState([]);

  useEffect(() => {
    let pos = 0;
    let clients =
      bestClients &&
      bestClients.map((client) => {
        pos++;
        return { ...client, position: pos };
      });
    setEditedClients(clients);
    //console.log("new position", clients);
  }, [bestClients]);

  return (
    <Card variant="outlined">
      <CardContent>
        {editedClients && editedClients.length !== 0 ? (
          <>
            <Box
              sx={{
                display: {
                  sm: "flex",
                  xs: "block",
                },
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "0",
                  }}
                  gutterBottom
                >
                  Clientes Frequentes
                </Typography>
              </Box>

              <Box
                sx={{
                  marginLeft: "auto",
                  mt: {
                    lg: 0,
                    xs: 2,
                  },
                }}
              >
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  {/* <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={age}
                onChange={handleChange}
                label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Novembro de 2023</MenuItem>
                <MenuItem value={20}>Outubro de 2023</MenuItem>
                <MenuItem value={30}>Setembro de 2023</MenuItem>
              </Select> */}
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                height: "385px",
                overflow: "auto",
                mt: 3,
              }}
            >
              <PerfectScrollbar>
                <Table
                  aria-label="simple table"
                  sx={{
                    mt: 3,
                    whiteSpace: "nowrap",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          variant="h6"
                        ></Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="h6">
                          Posição
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="h6">
                          Produto
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="h6">
                          Pedidos
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {editedClients &&
                      editedClients.map((product) => (
                        <TableRow key={product._id}>
                          <TableCell>
                            <Avatar
                              sx={{
                                width: "18px",
                                height: "18px",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              {product.position}º
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <Tooltip title={product.name}>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      textTransform: "uppercase",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {product.name.length > 15
                                      ? `${product.name.substring(0, 15)}...`
                                      : product.name}
                                  </Typography>
                                </Tooltip>
                                <Typography variant="h6">
                                  {product.phone}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              sx={{
                                pl: "4px",
                                pr: "4px",
                                backgroundColor: "#e7e7e7",
                                color: "#000",
                              }}
                              size="small"
                              label={product.totalOrders}
                            ></Chip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientOrdersPerformance;
