import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import "leaflet/dist/leaflet.css";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Chart from "react-apexcharts";
import currency from "currency-formatter";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { addressPoints } from "./realworld_sample";

const options = ["Action", "Another Action", "Something else here"];

const activities = [
  {
    time: "09.50",
    color: "success.main",
    text: "Novo produto criado",
  },
  {
    time: "09.46",
    color: "secondary.main",
    text: "Pagamento recebido no valor de R$ 39,50",
  },
  {
    time: "09.47",
    color: "primary.main",
    text: "Novo pedido",
  },
  {
    time: "09.48",
    color: "warning.main",
    text: "Produto fora de estoque",
  },
  {
    time: "09.49",
    color: "error.main",
    text: "Pedido cancelado",
  },
];

const OrdersMap = ({ ordersData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [average, setAverage] = useState(null);

  const [mapPoints, setMapPoints] = useState(null);

  const open = Boolean(anchorEl);

  function calculateDistance(coord1, coord2) {
    const [x1, y1] = coord1;
    const [x2, y2] = coord2;
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }
  function groupPointsByProximity(data, proximityThreshold) {
    const groupedData = [];
    for (let i = 0; i < data.length; i++) {
      const currentPoint = data[i];
      let groupFound = false;
      for (let j = 0; j < groupedData.length; j++) {
        const group = groupedData[j];
        const distance = calculateDistance(
          currentPoint.slice(0, 2),
          group.centroid.slice(0, 2)
        );
        if (distance <= proximityThreshold) {
          group.points.push(currentPoint);
          group.centroid = calculateCentroid(group.points);
          groupFound = true;
          break;
        }
      }
      if (!groupFound) {
        groupedData.push({
          points: [currentPoint],
          centroid: currentPoint.slice(0, 2),
        });
      }
    }
    return groupedData;
  }
  function calculateCentroid(points) {
    let xSum = 0;
    let ySum = 0;
    for (const point of points) {
      xSum += point[0];
      ySum += point[1];
    }
    return [xSum / points.length, ySum / points.length];
  }
  function sumThirdValueInGroups(groupedData) {
    for (const group of groupedData) {
      let sum = 0;
      for (const point of group.points) {
        sum += parseInt(point[2]);
      }
      group.sum = sum;
    }
    return groupedData;
  }

  useEffect(() => {
    if (ordersData) {
      const points = ordersData
        .filter((o) => o.modality === 1)
        .map((order) => {
          return [
            parseFloat(order.address.coordinates.lat),
            parseFloat(order.address.coordinates.lon),
            1,
          ];
        });

      //TESTAR AGRUPAMENTO
      const proximityThreshold = 0.01; // Adjust this value based on your desired proximity
      const groupedData = groupPointsByProximity(points, proximityThreshold);
      const groupedDataWithSum = sumThirdValueInGroups(groupedData);
      //TESTAR AGRUPAMENTO

      let threshouldPoints = groupedDataWithSum.map((p) => {
        //PUSH THE OTHER POINTS (GROUP POINTS)
        return [p.centroid[0], p.centroid[1], p.sum];
      });

      groupedDataWithSum.map((p) => {
        //PUSH THE OTHER POINTS (GROUP POINTS)
        threshouldPoints.push(...p.points);
      });

      //console.log("threshouldPoints", threshouldPoints);

      //console.log("points", points);
      setMapPoints(points);
    }
  }, [ordersData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        pb: 0,
      }}
    >
      <CardContent
        sx={{
          pb: "0 !important",
        }}
      >
        {ordersData ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mb: 5,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "h3.fontSize",
                    marginBottom: "0",
                  }}
                  gutterBottom
                >
                  Pedidos por Área
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                  }}
                >
                  Registro de atividades por localização
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                }}
              >
                <IconButton
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertOutlinedIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {/* {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={handleClose}
                    >
                      {option}
                    </MenuItem>
                  ))} */}
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "15px",
              }}
            >
              {ordersData && mapPoints && (
                <MapContainer
                  center={[-3.7511801, -38.5465728]}
                  zoom={13}
                  style={{ height: "700px" }}
                >
                  <HeatmapLayer
                    fitBoundsOnLoad={false}
                    fitBoundsOnUpdate={false}
                    minOpacity={0.05}
                    points={mapPoints}
                    longitudeExtractor={(m) => m[1]}
                    latitudeExtractor={(m) => m[0]}
                    intensityExtractor={(m) => parseFloat(m[2])}
                  />
                  <TileLayer
                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                </MapContainer>
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default OrdersMap;
