import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CircularProgress,
  MenuItem,
  Select,
  Chip,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import OrderBranchChip from "../../orders/components/OrderBranch";
import ExTable from "./ExTable";
import { IngredientsBowl } from "../../../assets/svgIcons/icons";
import locations from "../../../services/locations";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const ProductPerformance = ({ bestSelling }) => {
  const handleChange = (event) => {
    //setAge(event.target.value);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        {bestSelling && bestSelling.length !== 0 ? (
          <>
            <Box
              sx={{
                display: {
                  sm: "flex",
                  xs: "block",
                },
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    marginBottom: "0",
                  }}
                  gutterBottom
                >
                  Itens Mais Pedidos
                </Typography>
              </Box>

              <Box
                sx={{
                  marginLeft: "auto",
                  mt: {
                    lg: 0,
                    xs: 2,
                  },
                }}
              >
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  {/* <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={age}
                onChange={handleChange}
                label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Novembro de 2023</MenuItem>
                <MenuItem value={20}>Outubro de 2023</MenuItem>
                <MenuItem value={30}>Setembro de 2023</MenuItem>
              </Select> */}
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                height: "465px",
                overflow: "auto",
                mt: 3,
              }}
            >
              <PerfectScrollbar>
                <Table
                  aria-label="simple table"
                  sx={{
                    mt: 3,
                    whiteSpace: "nowrap",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          variant="h6"
                        ></Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="h6">
                          Posição
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="h6">
                          Produto
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="h6">
                          Pedidos
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bestSelling &&
                      bestSelling.map((product) => (
                        <TableRow key={product._id}>
                          <TableCell>
                            {product.image && product.image !== "" ? (
                              <img
                                src={`${locations.BASE_URL}/uploads/${product.image}`}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <IngredientsBowl size={50} color="#CCC" />
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              {product.position}º
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "600",
                                  }}
                                >
                                  {product.name}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  sx={{
                                    fontSize: "13px",
                                  }}
                                >
                                  <OrderBranchChip branch={product.branch} />
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              sx={{
                                pl: "4px",
                                pr: "4px",
                                backgroundColor: "#e7e7e7",
                                color: "#000",
                              }}
                              size="small"
                              label={product.totalSales}
                            ></Chip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductPerformance;
