const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      return { ...state, company: action.payload };
    case "SET_ALLOWEDCOMPANIES":
      return { ...state, companies: action.payload };
    case "SET_BRANCHES":
      return { ...state, branches: action.payload };
    case "SET_CURRENTBRANCH":
      return { ...state, currentBranch: action.payload };

    //VENDA PDV
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload };
    case "SET_PRODUCTS":
      return { ...state, products: action.payload };
    case "SET_COMPLEMENTGROUPS":
      return { ...state, complementGroups: action.payload };
    case "SET_COMPLEMENTS":
      return { ...state, complements: action.payload };
    case "SET_OFFERS":
      return { ...state, offers: action.payload };
    case "SET_COUPONS":
      return { ...state, coupons: action.payload };
    //VENDA PDV
    case "SET_RECOVERINGCARTS":
      return { ...state, recoveringCart: action.payload };
    case "SET_ORDERS":
      return { ...state, orders: action.payload };
    case "SET_OPEN":
      return { ...state, isOpen: action.payload };
    case "SET_USER":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export { reducer };
